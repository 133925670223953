<template>
	<div class="subcategory">
		<div class="subcategory main-page">
			<div class="row bear-crumbs grey  lighten-4"></div>

			<div class="page-title">
				<h3>{{ getSubCats(this.$route.path) }}</h3>
			</div>

			<section class="subcategory ">
				<div v-if="products" class=" row center  text-center">
					<div
						v-for="product in products"
						:key="product.id"
						class="product-item col s12 m6 l4 xl4"
					>
						<div class="product-card col s12 m6 l4 xl3">
							<div class="img-container" @click="toProduct(product)">
								<img
									:src="product.mainImageSrc"
									class="img responsive-img "
									alt=""
								/>
							</div>
							<div class="actions">
								<div class="card-info ">
									<span class="title">{{ product.title }}</span>
									<span class="price">{{ product.price | currency }}</span>
								</div>

								<button
									v-if="cart.find((val) => val.productId === product.id)"
									class="btn btn-buy btn-flat row waves-effect  waves-light disabled"
									@click.prevent="addProductToCart(product)"
								>
									<i class="material-icons  white-text">check_circle_outline</i>
								</button>

								<button
									v-else
									class="btn btn-buy btn-flat row waves-effect waves-light"
									@click.prevent="addProductToCart(product)"
								>
									<i class="material-icons white-text">shopping_cart</i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<h3 v-else>Товаров в выбранной категори пока что нет.</h3>
			</section>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	data: () => ({
		name: 'subcategory',
		categories: [],
		currentCategory: '',
		select: null,
		current: null,
		matchingProducts: [],
		cart: [],
		allProducts: [],
		loading: true,
		productItem: {
			id: '',
		},
	}),
	// mounted() {

	// },
	created() {
		this.categories = this.$store.getters.getCategories;
		this.allProducts = this.$store.getters.getProducts;
		this.cart = this.$store.getters.getCart;

		if (this.allProducts.length === 0 || this.categories === 0) {
			this.$store.dispatch('fetchProducts');
			this.$store.dispatch('fetchCategories');
			return;
		}
	},
	computed: {
		...mapGetters(['getSubCategories']),

		products() {
			this.$store.commit('filterProducts', this.$route.path);
			return this.$store.getters.getSubCatProducts;
		},
	},
	methods: {
		getSubCats(route) {
			return this.getSubCategories(route);
		},

		toProduct(p) {
			window.scroll({
				top: 10,
				left: 0,
			});
			this.$router.push(`/product/${p.id}`);
		},

		addProductToCart(product) {
			this.productItem.id = product.id;

			this.cartItem = {
				productId: product.id,
				productImage: product.mainImageSrc,
				productTitle: product.title,
				amount: 1,
				price: product.price,
				sum: 1 * product.price,
			};
			this.$addToCart();
			this.$store.commit('addProductToCart', this.cartItem);
		},
	},
};
</script>

<style lang="less"></style>
